import React from 'react';

import { HeaderMastercardEvent } from '@widgets/header/HeaderMastercardEvent';
import { FooterMastercardEvent } from '@widgets/footers/FooterMastercardEvent';

interface LayoutMastercardEventProps {
  chilren: React.ReactElement;
}

export const LayoutMastercardEvent: React.FC<LayoutMastercardEventProps> = ({ children }) => {
  return (
    <>
      <HeaderMastercardEvent />
      <main className="main">{children}</main>
      <FooterMastercardEvent />
    </>
  );
};
