import * as React from 'react';
import { changeLanguage } from 'i18next';
import { navigate } from 'gatsby';

import { SecondaryDropdown } from '@shared/dropdowns/secondary';
import { Element } from '@shared/interfaces/element.interface';
import { Option } from '@shared/interfaces/option.interface';

interface ILanguageSelect extends Element {
	mode?: 'dark' | 'default';
	menuPlacement?: 'string';
	onLanguageChange?: (language: string) => void;
}

import languages from '@values/languages';
import { getFullPathname, getPathWithLanguage } from '@shared/utils/path';
import { useLanguage } from '@hooks/useLanguage';

export const LanguageSelect: React.FC<ILanguageSelect> = ({ onLanguageChange, className, mode, menuPlacement = 'bottom' }) => {
	const language = useLanguage();
	const resolvedLanguage = languages.find((l) => l.value === language)?.value || 'uk';

	const onChange = async (option: Option | null) => {
		if (!option) return;

		const language = option.value;
		await changeLanguage(language);

		await navigate(getPathWithLanguage(getFullPathname(), language));

		if (onLanguageChange) {
			await Promise.resolve(onLanguageChange(language));
		}
	};

	return (
		<SecondaryDropdown
			value={resolvedLanguage}
			options={languages}
			className={className}
			mode={mode}
			onChange={onChange}
			menuPlacement={menuPlacement}
		/>
	);
};
