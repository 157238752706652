import React from 'react';
import '../i18n';
import { LayoutMastercardEvent } from './layouts/LayoutMastercardEvent';
import { PageMetadata } from '@widgets/metadata/PageMetadata';

function useComponentState({ pageContext }: any) {
	const language = (pageContext.intl?.language || 'uk') as 'en' | 'uk';

	return {
		layout: LayoutMastercardEvent,
		language,
	};
}

// TODO: refactor layouts handling
export const WrapPageElement = ({ element, props }: any) => {
	const { layout: Layout, language } = useComponentState(props);

	return (
		<>
			<PageMetadata language={language || 'uk'} />

			<div className='flex flex-col min-h-screen'>
				<Layout {...props}>{element}</Layout>
			</div>
		</>
	);
};
