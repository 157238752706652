import * as React from 'react';

import { LanguageSelect } from '@shared/ui/selects/LanguageSelect';

import { GenericLink, OuterLink } from '@shared/ui/GenericLink';
import { TryzubIcon } from '@shared/icons/tryzub';
import { LogoIcon } from '@shared/icons/logo';
import { Text } from '@shared/ui/text';
import { useTranslation } from 'react-i18next';

const hrefMail = 'mailto:help@edopomoga.gov.ua';
const textMail = 'help@edopomoga.gov.ua';

export const FooterMastercardEvent = () => {
	const { t } = useTranslation();
	const currentYear = React.useMemo(() => {
		return new Date().getFullYear();
	}, []);
	const copyright = `${currentYear}. ${t('rights-protected')}`;

	return (
		<footer className='py-3.125 text-white text-base bg-black'>
			<div className='container_2xl flex flex-col lg:flex-row justify-between'>
				<div className='flex items-center mb-5 lg:mb-0'>
					<GenericLink className='flex mr-3' type='page' to='/'>
						<TryzubIcon className='shrink-0 mr-2 w-[3rem] h-[3rem] text-white' />
						<LogoIcon className='shrink-0 w-[3rem] h-[3rem]' />
					</GenericLink>
					<Text size='xxs' className='opacity-70'>
						edopomoga.gov.ua <br /> {copyright}
					</Text>
				</div>

				<div className='flex flex-col md:flex-row items-start md:items-center space-y-5 md:space-y-0 md:space-x-8'>
					<LanguageSelect mode='dark' menuPlacement='top' />
					<div className='flex flex-col xs:flex-row whitespace-nowrap'>
						<p className='mr-2'>{t('our-contacts')}:</p>
						<OuterLink to={hrefMail} className='underline'>
							{textMail}
						</OuterLink>
					</div>
				</div>
			</div>
		</footer>
	);
};
