import { configureStore } from '@reduxjs/toolkit';
import { emptySplitApi } from './emptySplitApi';

import { applicationsReducer } from './slices/applications/slice';
import { customerReducer } from './slices/customer/slice';
import { volunteerReducer } from './slices/volunteer/slice';
import { authReducer } from './slices/auth';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    applications: applicationsReducer,
    customer: customerReducer,
    volunteer: volunteerReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(emptySplitApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
