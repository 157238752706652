export default {
	title: {
		uk: 'Отримайте допомогу, допоможіть з рук в руки, або онлайн',
		en: 'Help Ukrainians online',
	},
	description: {
		uk: 'На платформі єДопомога ви можете залишити заявку на отримання допомоги, допомогти іншим передавши необхідні речі з рук в руки, або допомогти онлайн',
		en: 'Help people suffering from russian invasion into Ukraine',
	},
};
