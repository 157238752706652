export enum ApplicantType {
  LegalEntity = 'legal-entity',
  HumanBeing = 'human-being',
}

export enum UserType {
  Volunteer = 'volunteer',
  InNeed = 'in_need',
}

export enum HowToHelpType {
  HandToHand = 'hand',
  OnlineFood = 'online-food',
  OnlineMedicaments = 'online-medicaments',
}

export enum Routes {
  Home = '/',
  Cabinet = '/cabinet',
  Volunteer = '/cabinet/volunteer',
  Customer = '/cabinet/customer',
  Auth = '/auth',
  AuthSignIn = '/auth/sign-in',
  AuthSignOut = '/auth/sign-out',
  Receipts = '/receipts',

  Certificate = '/cert',

  MasterCardEvent = '/events/mastercard',
}
