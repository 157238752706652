import * as React from 'react';

import { LogoIcon } from '@shared/icons/logo';
import { TryzubIcon } from '@shared/icons/tryzub';
import { Text } from '@shared/ui/text';
import { GenericLink } from '@shared/ui/GenericLink';
import { useLanguage } from '@hooks/useLanguage';
import { useWindowScroll } from 'react-use';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { LogoTextIcon } from '@shared/icons/logo-text';

interface HeaderBaseProps {
	headerRef?: React.RefObject<HTMLDivElement> | null;
	children?: React.ReactNode;
	containerClasses?: string;
	logoClasses?: string;
}

function useComponentState(props: HeaderBaseProps) {
	const { t } = useTranslation();
	const language = useLanguage();
	const { y: windowScrollY } = useWindowScroll();
	const hasWindowScroll = windowScrollY > 0;
	const headerStyles = clsx('sticky left-0 top-0 right-0 py-2 z-30 lg:py-4 bg-white transition-shadow', {
		'shadow-header': hasWindowScroll,
	});

	const containerClasses = props.containerClasses ?? 'container_2xl flex items-center';
	const logoClasses = props.logoClasses ?? 'flex items-center';

	return {
		...props,
		language,
		headerStyles,
		containerClasses,
		logoClasses,
		t,
	};
}

export const HeaderBase: React.FC<HeaderBaseProps> = (props) => {
	const { t, language, containerClasses, logoClasses, headerRef = null, children, headerStyles } = useComponentState(props);

	return (
		<header ref={headerRef} className={headerStyles}>
			<div className={containerClasses}>
				<div className='flex items-center'>
					<GenericLink className={logoClasses} type='page' to='/'>
						<TryzubIcon className='shrink-0 w-[2rem] h-[2rem] mr-2 md:w-[3rem] md:h-[3rem]' />
						<LogoIcon className='shrink-0 w-[2rem] h-[2rem] mr-2 md:w-[3rem] md:h-[3rem] md:mr-4' />
						<div className='flex flex-col justify-between min-w-[89px] md:min-w-[100px]'>
							<Text weight='bold' size='sm' className=' md:text-base'>
								{t('dopomoga')}
							</Text>
							{language === 'uk' && <LogoTextIcon className='w-[70px] h-[11px] md:w-[92px] md:h-[13px]' />}
						</div>
					</GenericLink>
				</div>
				{children}
			</div>
		</header>
	);
};
