import React, { useEffect, useState } from 'react';

import { useNavigate } from '@hooks/useNavigate';
import { extractPathLanguage, getFullPathname } from '@shared/utils/path';
import { useLanguage } from '@hooks/useLanguage';
import { PageMetadata } from '@widgets/metadata/PageMetadata';

interface LanguageProviderProps {
	children?: React.ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
	const pathLanguage = typeof window !== 'undefined' ? extractPathLanguage(window.location.pathname) : null;
	const language = useLanguage();

	const navigate = useNavigate();
	const [languageInitialized, setLanguageInitialized] = useState(pathLanguage === language);

	useEffect(() => {
		if (languageInitialized) return;

		if (pathLanguage !== language) {
			navigate(getFullPathname());
		}

		setLanguageInitialized(true);
	}, [language, pathLanguage]);

	if (!languageInitialized) return <PageMetadata language={language || 'uk'} />;

	return <>{children}</>;
};
