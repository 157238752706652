import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CustomerSlice {
  tab: string;
  modal: string | null;
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    tab: 'create',
    modal: null,
  } as CustomerSlice,
  reducers: {
    changeActiveTab: (state, { payload }: PayloadAction<string>) => {
      state.tab = payload;
    },
    setModal: (state, { payload }: PayloadAction<string | null>) => {
      state.modal = payload;
    },
  },
});

export const customerReducer = customerSlice.reducer;

export const customerActions = {
  ...customerSlice.actions,
};
