import { navigate } from 'gatsby';
import { useCallback } from 'react';
import { useGetLanguageLink } from './useGetLanguageLink';

export function useNavigate() {
  const getLanguageLink = useGetLanguageLink();

  const customNavigate: typeof navigate = useCallback(
    async (to: string | number, options = {}) => {
      if (typeof to === 'number') return navigate(to);
      return navigate(getLanguageLink(to), options);
    },
    [getLanguageLink],
  );

  return customNavigate;
}
