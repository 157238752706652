import * as React from 'react';

import { HeaderBase } from './HeaderBase';

import { LanguageSelect } from '@shared/ui/selects/LanguageSelect';
import { MspLogoIcon } from '@shared/icons/msp';

export const HeaderMastercardEvent = () => {
	return (
		<HeaderBase containerClasses='container_2xl flex items-center justify-between' logoClasses='flex items-center'>
			<MspLogoIcon className='hidden h-auto sm:block sm:w-[156px] md:w-[191px]' />
			<LanguageSelect />
		</HeaderBase>
	);
};
