export enum AppMode {
	Development = 'development',
	Production = 'production',
}

interface AppConfig {
	mode: AppMode;
	api: {
		url: string;
	};
	i18n: {
		languages: string[];
		preloadLanguages: string[];
	};
}

const config: AppConfig = {
	mode: (process.env.NODE_ENV as AppMode) || AppMode.Development,
	api: {
		url: process.env.GATSBY_API_URL || 'http://localhost:3001/api',
	},
	i18n: {
		languages: (process.env.GATSBY_LANGUAGES || 'en,uk').split(','),
		preloadLanguages: (process.env.GATSBY_PRELOAD_LANGUAGES || 'en,uk').split(','),
	},
};

export default config;
