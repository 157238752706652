import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicantType, HowToHelpType } from '@shared/enums';
import { getSearchParams } from '@shared/utils/path';

interface VolunteerFilters {
  way: HowToHelpType;
  applicantType: ApplicantType | null;
  regionId: number | null;
  localityId: number | null;
  categoryIds: number[];
  categoryItemIds: number[];
  applicantCategoryIds: number[];
}

interface VolunteerPagination {
  page: number;
  perPage: number;
}

interface VolunteerSlice {
  filters: VolunteerFilters;
  pagination: VolunteerPagination;
  tab: string;
}

export const volunteerSlice = createSlice({
  name: 'volunteer',
  initialState: () => {
    const searchParams = getSearchParams();

    return {
      filters: {
        way: searchParams?.get('way') || HowToHelpType.OnlineFood,
        applicantType: null,
        regionId: null,
        localityId: null,
        categoryIds: [],
        categoryItemIds: [],
        applicantCategoryIds: [],
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      tab: 'new',
    } as VolunteerSlice;
  },
  // TODO: refactor reset filters
  reducers: {
    changeActiveTabway: (state, { payload }: PayloadAction<{ tab: string }>) => {
      state.tab = payload.tab;

      // TODO: pass as argument
      // state.filters.way = HowToHelpType.OnlineFood;
    },
    changeActiveTab: (state, { payload }: PayloadAction<string>) => {
      state.tab = payload;

      state.filters.applicantType = null;
      state.filters.regionId = null;
      state.filters.localityId = null;
      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
    },

    setApplicantTypeFilter: (state, { payload }: PayloadAction<ApplicantType | null>) => {
      state.filters.applicantType = payload;

      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
    },

    setWayFilter: (state, { payload }: PayloadAction<HowToHelpType>) => {
      state.filters.way = payload;

      state.filters.applicantType = null;
      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
    },
    setCategoryIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.filters.categoryIds = payload;

      state.filters.categoryItemIds = [];
    },
    setCategoryItemsIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.filters.categoryItemIds = payload;
    },
    setApplicantCategoryIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.filters.applicantCategoryIds = payload;
    },
    setRegionIdFilter: (state, { payload }: PayloadAction<number | null>) => {
      state.filters.regionId = payload;

      state.filters.localityId = null;
      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
    },
    setLocalityIdFilter: (state, { payload }: PayloadAction<number | null>) => {
      state.filters.localityId = payload;

      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
    },
    setPagination: (state, { payload }: PayloadAction<{ page: number; perPage: number }>) => {
      state.pagination.page = payload.page;
      state.pagination.perPage = payload.perPage;
    },
    resetPagination: (state) => {
      state.pagination.page = 1;
      state.pagination.perPage = 10;
    },
  },
});

export const volunteerReducer = volunteerSlice.reducer;

export const volunteerActions = {
  ...volunteerSlice.actions,
};
