import * as React from 'react';
import clsx from 'clsx';

import { Element } from '@shared/interfaces/element.interface';

type TextWeight = 'light' | 'regular' | 'medium' | 'bold';
export type TextSize = 'none' | 'xxs' | 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
type TextAlign = 'center' | 'left' | 'right';

interface TextOptions extends Element {
  weight?: TextWeight;
  size?: TextSize;
  align?: TextAlign;
}

export interface TextProps extends TextOptions, Element {
  children: React.ReactNode;

  component?: 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small';
}

interface TextElements {
  root: string;
}

interface StyleConfig {
  size: Record<TextSize, TextElements>;
  align: Record<TextAlign, TextElements>;
  weight: Record<TextWeight, TextElements>;
}

const styleConfig: StyleConfig = {
  size: {
    none: {
      root: 'text-none',
    },
    xxs: {
      root: 'text-xxs',
    },
    xs: {
      root: 'text-xs',
    },
    sm: {
      root: 'text-sm',
    },
    base: {
      root: 'text-base',
    },
    md: {
      root: 'text-md',
    },
    lg: {
      root: 'text-lg',
    },
    xl: {
      root: 'text-xl',
    },
    '2xl': {
      root: 'text-2xl',
    },
    '3xl': {
      root: 'text-3xl',
    },
    '4xl': {
      root: 'text-4xl',
    },
    '5xl': {
      root: 'text-5xl',
    },
  },
  weight: {
    light: {
      root: 'font-light',
    },
    regular: {
      root: 'font-regular',
    },
    medium: {
      root: 'font-medium',
    },
    bold: {
      root: 'font-bold',
    },
  },
  align: {
    left: {
      root: 'text-left',
    },
    center: {
      root: 'text-center',
    },
    right: {
      root: 'text-right',
    },
  },
};

function toClassNames(options: Required<TextOptions>) {
  const { weight, size, align, className } = options;

  return {
    root: clsx(styleConfig.weight[weight].root, styleConfig.size[size]?.root, styleConfig.align[align].root, className),
  };
}

export const Text: React.FC<TextProps> = (props) => {
  const { component = 'span', weight = 'regular', size = 'base', align = 'left', className = '' } = props;
  const classNames = toClassNames({ weight, size, align, className });

  return React.createElement(component, {
    className: classNames.root,
    children: props.children,
  });
};
